.bookappointment{
    background-color: #1f1c1c; /* Green */
    border: none;
    color: white;
    padding: 7px 18px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 14px;
    border-radius: 8px;
}



.btn-grad15 {
    background-image: linear-gradient(to right, #cb0000 0%, #f3ff05  51%, #2b5876  100%);
    margin: 0px;
    padding: 9px 29px;
    text-align: center;
    /* text-transform: uppercase; */
    transition: 0.5s;
    background-size: 200% auto;
    color: white;            
    box-shadow: 0 0 20px #eee;
    border-radius: 12px;
    display: block;
  }
  
  .btn-grad15:hover {
    background-position: right center; /* change the direction of the change here */
    color: #fff;
    text-decoration: none;
  }