.magicberhampore{
    background-color: rgb(76, 20, 49);
}



* {box-sizing: border-box}

/* Container needed to position the overlay. Adjust the width as needed */
.hoverimage1 {
  position: relative;

}

/* Make the image to responsive */
.image2 {
  display: block;
  width: 100%;
  height: auto;
}

/* The overlay effect - lays on top of the container and over the image */
.overlay1 {
  position: absolute;
  bottom: 0;
  background: rgb(20, 20, 21);
  background: rgba(24, 25, 25, 0.5); /* Black see-through */
  color: #f1f1f1;
  width: 100%;
  transition: .5s ease;
  opacity:0;
  color: white;
  font-size: 20px;
  padding: 20px;
  text-align: center;
  border-radius: 0px;
}

/* When you mouse over the container, fade in the overlay title */
.hoverimage1:hover .overlay1 {
  opacity: 2;
}


.blink { 
    animation: blink-animation 1s infinite; 
  } 
    
  @keyframes blink-animation { 
    0% { 
      opacity: 1; 
    } 
    50% { 
      opacity: 0; 
    } 
    100% { 
      opacity: 1; 
    } 
  }