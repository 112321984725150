.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


a {
  text-decoration: none !important;

  
}


.encode-sans-expanded-light {
  font-family: "Encode Sans Expanded", sans-serif;
  font-weight: 300;
  font-style: normal;
}


/* .update{
  font-family: "Encode Sans Expanded", sans-serif;
  font-weight: 300;
  font-style: normal;
} */

.update p{
  font-family: "Briem Hand", cursive;
  font-optical-sizing: auto;
  font-weight: 300;
  font-style: normal;
}

.container{
  font-family: "Ubuntu", sans-serif;
  font-weight: 400;
  font-style: normal;
}



.btn-grad65 {
  background-image: linear-gradient(to right, #04696b 0%, #f1d9d9  51%, #166d7c  100%);
  margin: 0px;
  padding: 4px 19px;
  text-align: center;
  /* text-transform: uppercase; */
  transition: 0.5s;
  background-size: 200% auto;
  color: white;            
  box-shadow: 0 0 20px #eee;
  border-radius: 2px;
  display: block;
  border-radius: 8px;
}

.btn-grad65:hover {
  background-position: right center; /* change the direction of the change here */
  color: #fff;
  text-decoration: none;
}

.giftberhampore{
  background-color: #abd7d8;
}

.button50{
  background-color: #0b1734; /* Green */
  border: none;
  color: white;
  padding: 7px 23px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  -webkit-transition-duration: 0.4s; /* Safari */
  transition-duration: 0.4s;
  border-radius: 9px;
}

.button51{
  box-shadow: 0 8px 16px 0 rgba(78, 2, 23, 0.2), 0 6px 20px 0 rgba(0,0,0,0.19);
}

.button80{
  background-color: #216d2b; /* Green */
  border: none;
  color: white;
  padding: 7px 23px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  -webkit-transition-duration: 0.4s; /* Safari */
  transition-duration: 0.4s;
  border-radius: 9px;
}

.button81{
  box-shadow: 0 8px 16px 0 rgba(78, 2, 23, 0.2), 0 6px 20px 0 rgba(0,0,0,0.19);
}